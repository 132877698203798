import { useRouteError } from 'react-router-dom'
import { AlertCircle, FileWarning, Terminal } from 'lucide-react'

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'

type RouterError = {
  statusText?: string
  message?: string
}

export function ErrorPage() {
  const error = useRouteError() as RouterError
  console.error(error)

  return (
    <div className="grid h-screen w-screen place-items-center">
      <div className="w-[400px]">
        <ErrorElement />
      </div>
    </div>
  )
}

export function ErrorElement() {
  const error = useRouteError() as RouterError
  console.error(error)

  let msg = error.statusText || error.message

  // if the error is failed to fetch dynamically imported module
  // let's try to reload the page
  // but cap at 3 reloads in case something is going terribly wrong
  if (msg?.includes('Failed to fetch dynamically imported module')) {
    let search = new URLSearchParams(window.location.search)
    let redirects = Number(search.get('reload') ?? 0) + 1
    if (redirects === 1) {
      search.set('reload', String(redirects))
      window.location.search = search.toString()
      return null
    }
  }

  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>
        So sorry, an unexpected error has occurred:
        <br />
        <br />
        {msg}
      </AlertDescription>
    </Alert>
  )
}
