// This is needed to polyfill the global Buffer object for the address-validation lib
import { Buffer } from 'buffer'
globalThis.Buffer = Buffer

import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'

import { ErrorPage, ErrorElement } from '@/error-page'
// import Root from '@/routes/root'
import { Toaster } from '@/components/ui/toaster'

import './index.css'

let router = createBrowserRouter(
  [
    {
      path: '/',
      async lazy() {
        let { Root } = await import('./routes/root')
        return { Component: Root }
      },
      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorElement />,
          children: [
            {
              index: true,
              loader() {
                return redirect('/balances')
              },
            },
            {
              path: 'trade',
              async lazy() {
                let { Dashboard } = await import('./routes/dashboard')
                return { Component: Dashboard }
              },
            },
            {
              path: 'overview',
              async lazy() {
                let { Balances } = await import('./routes/balances')
                return { Component: Balances }
              },
            },
            {
              path: 'balances',
              loader() {
                return redirect('/overview')
              },
            },
            {
              path: 'reports',
              children: [
                {
                  index: true,
                  loader() {
                    return redirect('/reports/trades')
                  },
                },
                {
                  path: 'trades',
                  async lazy() {
                    let { TradeHistory } = await import(
                      './routes/reports/trades'
                    )
                    return { Component: TradeHistory }
                  },
                },
                {
                  path: 'balances',
                  async lazy() {
                    let { BalanceReport } = await import(
                      './routes/reports/balances'
                    )
                    return { Component: BalanceReport }
                  },
                },
                {
                  path: 'orders',
                  async lazy() {
                    let { OrderHistory } = await import(
                      './routes/reports/orders'
                    )
                    return { Component: OrderHistory }
                  },
                },
                // {
                //   path: 'deposits',
                //   async lazy() {
                //     let { Deposits } = await import('./routes/reports/deposits')
                //     return { Component: Deposits }
                //   },
                // },
                // {
                //   path: 'withdrawals',
                //   async lazy() {
                //     let { Withdrawals } = await import(
                //       './routes/reports/withdrawals'
                //     )
                //     return { Component: Withdrawals }
                //   },
                // },
                {
                  path: 'transfers',
                  async lazy() {
                    let { Transfers } = await import(
                      './routes/reports/transfers'
                    )
                    return { Component: Transfers }
                  },
                },
              ],
            },

            {
              path: 'user',
              children: [
                {
                  index: true,
                  loader() {
                    return redirect('/user/account')
                  },
                },
                {
                  path: 'account',
                  async lazy() {
                    let { UserSettings } = await import('./routes/user')
                    return { Component: UserSettings }
                  },
                },
                {
                  path: 'password',
                  async lazy() {
                    let { UserSettings } = await import('./routes/user')
                    return { Component: UserSettings }
                  },
                },
              ],
            },
            // {
            //   path: 'team',
            //   async lazy() {
            //     let { Team } = await import('./routes/team')
            //     return { Component: Team }
            //   },
            // },
            // {
            //   path: 'trade',
            //   async lazy() {
            //     let { Trade } = await import('./routes/trade')
            //     return { Component: Trade }
            //   },
            // },
            {
              path: 'settings',
              async lazy() {
                let { Settings } = await import('./routes/settings')
                return { Component: Settings }
              },
              children: [
                {
                  index: true,
                  loader: () => {
                    return redirect('/settings/crypto')
                  },
                },
                {
                  path: 'crypto',
                  async lazy() {
                    let { CryptoSettings } = await import(
                      './routes/settings/crypto'
                    )
                    return { Component: CryptoSettings }
                  },
                },
                {
                  path: 'bank',
                  async lazy() {
                    let { BankSettings } = await import(
                      './routes/settings/bank'
                    )
                    return { Component: BankSettings }
                  },
                },
                {
                  path: 'bank/new',
                  async lazy() {
                    let { BankAccountForm } = await import(
                      './routes/settings/new-bank-account'
                    )
                    return { Component: BankAccountForm }
                  },
                },
                // {
                //   path: 'ip',
                //   async lazy() {
                //     let { IpSettings } = await import('./routes/settings/ip')
                //     return { Component: IpSettings }
                //   },
                // },
                // {
                //   path: 'api',
                //   async lazy() {
                //     let { ApiSettings } = await import('./routes/settings/api')
                //     return { Component: ApiSettings }
                //   },
                // },
                {
                  path: 'users',
                  async lazy() {
                    let { UsersSettings } = await import(
                      './routes/settings/users'
                    )
                    return { Component: UsersSettings }
                  },
                },
              ],
            },
            // {
            //   path: 'reports',
            //   async lazy() {
            //     let { Reports } = await import('./routes/reports')
            //     return { Component: Reports }
            //   },
            // },
          ],
        },
      ],
    },
  ],
  { basename: '/app' }
)

function SandboxBanner() {
  let hostname = window.location.hostname
  if (hostname !== 'sandbox.677fg.com') {
    return null
  }

  return (
    <div className="pt-[36px]">
      <div className="fixed left-0 top-0 z-50 w-full bg-yellow-500 p-2 text-white">
        <div className="text-center text-sm font-bold uppercase">Sandbox</div>
      </div>
    </div>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <SandboxBanner />
    <RouterProvider router={router} />
    <Toaster />
  </>
)
